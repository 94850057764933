<template>
    <div class="admin-page">
        <PageTitle title="Administração" subtitle="Opções protegidas e visíveis apenas para administradores" />
        <template class="tabs-admin">
            <v-tabs :color="azulPadrao" show-arrows>
                <v-tab>Atendimentos</v-tab>
                <v-tab>Usuários</v-tab>
                <v-tab>Setores</v-tab>
                <v-tab>Status</v-tab>
                <v-tab>Canais</v-tab>
                <v-tab>Configurações</v-tab>
            
                <v-tab-item>
                    <AtendimentosAdminPage />
                </v-tab-item>
                <v-tab-item>
                    <UsuariosPage />
                </v-tab-item>
                <v-tab-item>
                    <SetoresPage />
                </v-tab-item>
                <v-tab-item>
                    <StatusPage />
                </v-tab-item>
                <v-tab-item>
                    <CanaisPage />
                </v-tab-item>
                <v-tab-item>
                    <ConfiguracoesPage />
                </v-tab-item>
            </v-tabs>
        </template>
    </div>
</template>

<script>
import PageTitle from '@/components/templates/PageTitle'
import AtendimentosAdminPage from '@/components/admin/AtendimentosAdminPage'
import UsuariosPage from '@/components/admin/UsuariosPage'
import SetoresPage from '@/components/admin/SetoresPage'
import StatusPage from '@/components/admin/StatusPage'
import CanaisPage from '@/components/admin/CanaisPage'
import ConfiguracoesPage from '@/components/admin/ConfiguracoesPage'
import { azulPadrao } from '@/config/global'

export default {
    name: 'AdminPage',
    components: {
        PageTitle,
        AtendimentosAdminPage,
        UsuariosPage,
        SetoresPage,
        StatusPage,
        CanaisPage,
        ConfiguracoesPage
    },
    data() {
        return {
            azulPadrao
        }
    }
}
</script>

<style>

</style>