<template>
    <v-container class="atendimentos-admin-page" fluid>
        <v-layout align-center justify-center>
            <v-flex>
                <v-data-table class="listagem-atendimentos" :item-class="corFonteUrgente" hide-default-footer :headers="colunas" :items="lista" no-data-text="Nenhum atendimento" :items-per-page="-1">
                    <template v-slot:[`item.ate_urgente`]="{ item }">
                        {{ formatarBoolTexto(item.ate_urgente) }}
                    </template>
                    <template v-slot:[`item.ate_criacao`]="{ item }">
                        {{ formatarDataHora(item.ate_criacao) }}
                    </template>
                    <template v-slot:[`item.ate_ultima_alteracao`]="{ item }">
                        {{ formatarDataHora(item.ate_ultima_alteracao) }}
                    </template>
                    <template v-slot:[`item.ate_conclusao`]="{ item }">
                        {{ formatarDataHora(item.ate_conclusao) }}
                    </template>
                    <template v-slot:[`item.btnActions`]="{ item }">
                        <v-btn class="mr-2" @click="openAtendimento(item.ate_id)" x-small :color="azulPadrao" dark>Abrir</v-btn>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { azulPadrao, formatarBoolTexto, formatarDataHora } from '@/config/global'

export default {
    name: 'ListagemAtendimentos',
    props: ['lista', 'colunas', 'destacarUrgente'],
    data() {
        return {
            azulPadrao,
            formatarBoolTexto,
            formatarDataHora
        }
    },
    methods: {
        openAtendimento(atendimentoId) {
            this.$router.push({
                name: 'atendimentoPage',
                params: {
                    atendimentoId
                }
            })
        },
        corFonteUrgente(item) {
            if (this.destacarUrgente) {
                return item.ate_urgente ? 'cor-destacada' : 'cor-normal'
            } else {
                return 'cor-normal'
            }
        }
    }
}
</script>

<style>
    .cor-normal {
        color: black;
    }

    .cor-destacada {
        color: rgb(232, 74, 82);
        font-weight: bold;
    }
</style>