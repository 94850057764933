<template>
    <div class="user-dropdown">
        <div class="user-dropdown-button">
            <v-icon class="mr-2">mdi-account</v-icon>
            <span>{{ user.usr_nome }}</span>
            <v-icon class="ml-1">mdi-menu-down</v-icon>
        </div>
        <div class="user-dropdown-items">
            <router-link to="/admin" v-if="user.usr_admin">
                <v-icon x-small class="mr-2" color="black">mdi-cog</v-icon>
                <i class="user-dropdown-item">Administração</i>
            </router-link>
            <a href @click.prevent="logout">
                <v-icon x-small class="mr-2" color="black">mdi-logout</v-icon>
                <i class="user-dropdown-item">Sair</i>
            </a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { userKey } from '@/config/global'

export default {
    name: 'UserDropdown',
    computed: mapState(['user']),
    methods: {
        logout() {
            localStorage.removeItem(userKey)
            this.$store.commit('setUser', null)
            this.$router.push( {
                name: 'loginPage'
            })
        }
    }
}
</script>

<style>
    .user-dropdown {
        position: relative;
        height: 100%;
    }

    .user-dropdown-button {
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 100;
        height: 100%;
        padding: 0px 20px;
    }

    .user-dropdown:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }

    .user-dropdown-items {
        position: absolute;
        right: 0px;
        background-color: #f9f9f9;
        min-width: 170px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 10px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }

    .user-dropdown:hover .user-dropdown-items {
        visibility: visible;
        opacity: 1;
    }

    .user-dropdown-items a {
        text-decoration: none;
        color: #000;
        padding: 10px;
    }

    .user-dropdown-items i {
        text-decoration: none;
        color: #000;
    }

    .user-dropdown-items a:hover {
        text-decoration: none;
        color: #000;
        padding: 10px;
    }

    .user-dropdown-item {
        font-style: normal;
    }
</style>