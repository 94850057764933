<template>
    <div class="nova-empresa-page">
        <PageTitle title="Cadastrar nova empresa" subtitle="O teste gratuito será válido por 5 dias com 5 usuários" />
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm10 md8 lg6>
                    <v-card elevation="4">
                        <v-card-text>
                            <v-form>
                                <v-text-field v-model="empresa.emp_razao_social" label="Razão social" :color="azulPadrao"></v-text-field>
                                <v-text-field v-model="empresa.emp_fantasia" label="Fantasia" :color="azulPadrao"></v-text-field>
                                <v-text-field v-model="empresa.emp_cnpj" label="CNPJ" :color="azulPadrao"></v-text-field>
                                <v-text-field v-model="empresa.emp_telefone" label="Telefone da empresa" :color="azulPadrao"></v-text-field>
                                <v-text-field v-model="empresa.usr_nome" label="Nome do responsável" :color="azulPadrao"></v-text-field>
                                <v-text-field v-model="empresa.usr_email" label="E-mail" :color="azulPadrao"></v-text-field>
                                <v-text-field v-model="empresa.usr_senha" label="Senha" type="password" :color="azulPadrao"></v-text-field>
                                <v-text-field v-model="empresa.usr_senha_confirmacao" label="Confirmação de senha" type="password" :color="azulPadrao"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-text>
                            <p class="text-left" style="font-weight: 300">Ao preencher o forulário acima, você está de acordo com a nossa <a href class="privacidade" @click.prevent="openPrivacidade"><strong>Política de Privacidade</strong></a></p>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn class="ml-2 mb-2" @click="cadastrarEmpresa" :color="azulPadrao" dark>Cadastrar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="mr-2 mb-2" @click="openLogin" :color="azulPadrao" dark outlined>Login</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import PageTitle from '@/components/templates/PageTitle'
import { azulPadrao, baseApiUrl, showError, urlSiteCodezz } from '@/config/global'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'NovaEmpresaPage',
    computed: mapState(['isUserVisible']),
    components: {
        PageTitle
    },
    data() {
        return {
            azulPadrao,
            empresa: {}
        }
    },
    methods: {
        cadastrarEmpresa() {
            axios.post(`${baseApiUrl}/nova_empresa`, this.empresa)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.$router.push({
                        name: 'loginPage'
                    })
                })
                .catch(showError)
        },
        openLogin() {
            this.$router.push({
                name: 'loginPage'
            })
        },
        openPrivacidade() {
            window.open(`${urlSiteCodezz}/politica-privacidade`, '_blank').focus()
        }
    },
    mounted() {
        if (this.isUserVisible) {
            this.$router.push({
                name: 'homePage'
            })
        }
    }
}
</script>

<style>
    .privacidade {
        text-decoration: none;
    }

    .privacidade strong {
        color: black;
    }
</style>