<template>
    <v-card class="criar-atendimento">
        <v-card-title>
            <span class="text-h5">Criar atendimento</span>
        </v-card-title>
        <v-card-text>
            <v-form>
                <v-text-field v-model="atendimento.ate_titulo" label="Título" :color="azulPadrao"></v-text-field>
                <v-text-field v-model="atendimento.ate_cliente" label="Cliente" :color="azulPadrao"></v-text-field>
                <v-textarea v-model="atendimento.ate_descricao" label="Descrição" :color="azulPadrao"></v-textarea>
                <v-select v-model="atendimento.can_id" :items="canais" item-value="can_id" item-text="can_nome" label="Canal" :color="azulPadrao"></v-select>
                <v-row>
                    <v-checkbox v-model="atendimento.ate_urgente" label="Urgente?" :color="azulPadrao" class="ml-2"></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-checkbox v-model="multiplosUsuarios" label="Múltiplos usuários?" :color="azulPadrao" class="mr-2"></v-checkbox>
                </v-row>
                <v-select v-model="atendimento.set_id" :items="setores" item-value="set_id" item-text="set_nome" label="Setor" :color="azulPadrao"></v-select>
                <v-select v-model="atendimento.usr_id" :items="usuarios" item-value="usr_id" item-text="usr_nome" label="Usuário" :color="azulPadrao" no-data-text="Você deve selecionar um setor"></v-select>
                <v-row v-if="multiplosUsuarios">
                    <v-spacer></v-spacer>
                    <v-btn @click="addUsuario" :color="azulPadrao" dark outlined x-small class="mr-2 mt-2 mb-2">Adicionar</v-btn>
                </v-row>
                <v-data-table v-if="multiplosUsuarios" class="listagem-usuarios" hide-default-footer :headers="colUsuarios" :items="usersAtendimentos" no-data-text="Nenhum usuário" :items-per-page="-1">
                    <template v-slot:[`item.btnActions`]="{ item }">
                        <v-btn @click="removeUsuario(item)" x-small :color="vermelhoPadrao" dark>Remover</v-btn>
                    </template>
                </v-data-table>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn class="ml-2 mb-2" @click="save" :color="azulPadrao" dark>Salvar</v-btn>
            <v-btn class="ml-2 mb-2" @click="cancel" :color="vermelhoPadrao" dark>Fechar</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { azulPadrao, vermelhoPadrao, baseApiUrl, showError } from '@/config/global'
import axios from 'axios'

export default {
    //Esse componente é uma dialog, ele não é aberto individualmente em uma tela como todos os outros, ele é aberto sobre a tela principal do sistema (HomePage)...
    name: 'CriarAtendimento',
    props: ['fecharDialog'],
    data() {
        return {
            azulPadrao,
            vermelhoPadrao,
            atendimento: {},
            canais: [],
            usuariosSetores: [],
            setores: [],
            usuarios: [],
            multiplosUsuarios: false,
            usersAtendimentos: [],
            colUsuarios: [                        
                {
                    text: 'Setor',
                    value: 'set_nome'
                },
                {                                                     
                    text: 'Usuário',
                    value: 'usr_nome'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        save() {
            if (!this.atendimento.ate_urgente) {
                this.atendimento.ate_urgente = false
            }

            let rota = ''
            let bodyReq = {}

            //Se tiver multiplos usuários, é outra rota pra fazer a inclusão dos atendimentos (faz um atendimento pra cada usuário informado)
            if (this.multiplosUsuarios) {
                bodyReq = { ...this.atendimento } //Copia o objeto sem referência (pra não modificar o original)
                delete bodyReq.set_id
                delete bodyReq.usr_id

                let users = this.usersAtendimentos.map(obj => ({ ...obj })) //Copia o array sem referência (pra não modificar o original)
                users.forEach(el => {
                    delete el.set_nome
                    delete el.usr_nome 
                })

                rota = 'atendimentos_users'
                bodyReq.usuarios = users
            } else {
                rota = 'atendimentos'
                bodyReq = { ...this.atendimento }
            }

            axios.post(`${baseApiUrl}/${rota}`, bodyReq)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.cancel()
                })
                .catch(showError)
        },
        cancel() {
            this.atendimento = {}
            this.usuarios = []
            this.multiplosUsuarios = false
            this.usersAtendimentos = []
            this.fecharDialog()
        },
        addUsuario() {
            if (!this.atendimento.set_id) {
                showError('Setor não informado.')
            } else if (!this.atendimento.usr_id) {
                showError('Usuário não informado.')
            } else {
                //Se informar duas ou mais vezes o mesmo usuário (usr_id), vai considerar apenas o primeiro (o que já está informado em "usersAtendimentos")
                const usr = {
                    set_id: this.atendimento.set_id,
                    set_nome: this.setores.find(obj => obj.set_id == this.atendimento.set_id).set_nome,
                    usr_id: this.atendimento.usr_id,
                    usr_nome: this.usuariosSetores.find(obj => obj.usr_id == this.atendimento.usr_id).usr_nome
                }

                const index = this.usersAtendimentos.findIndex(obj => obj.usr_id == usr.usr_id)
                if (index === -1) {
                    this.usersAtendimentos.push(usr)
                }

                //Limpa o usuário depois de adicionado
                this.atendimento.set_id = null
                this.atendimento.usr_id = null
            }
        },
        removeUsuario(usr) {
            const index = this.usersAtendimentos.findIndex(obj => obj.usr_id == usr.usr_id)
            this.usersAtendimentos.splice(index, 1)
        }
    },
    async mounted() {
        this.setores = []
        this.usuarios = []
        axios.get(`${baseApiUrl}/usuarios_setores`)
            .then(r => {
                let setAtual = -1
                r.data.data.forEach(el => {
                    this.usuariosSetores.push(el)
                    if (setAtual != el.set_id) {
                        setAtual = el.set_id
                        this.setores.push({
                            set_id: el.set_id,
                            set_nome: el.set_nome
                        })
                    }
                });

                //Ordem alfabética (setores)
                this.setores.sort((a, b) => {
                    return a.set_nome < b.set_nome ? -1 : a.set_nome > b.set_nome ? 1 : 0
                })
            })
            .catch(showError)
        
        this.canais = []
        let pg = 0
        let continuar = true
        while (continuar) {
            pg++
            await axios.get(`${baseApiUrl}/canais?page=${pg}`)
                .then(r => {
                    if (r.data.data.length == 0) {
                        continuar = false
                    } else {
                        r.data.data.forEach(el => {
                            if (el.can_ativo) {
                                this.canais.push(el)
                            }
                        });
                    }
                })
                .catch(e => {
                    continuar = false
                    showError(e)
                })
        }

        this.canais.sort((a, b) => {
            return a.can_nome < b.can_nome ? -1 : a.can_nome > b.can_nome ? 1 : 0
        })
    },
    watch: {
        'atendimento.set_id': function() {
            this.usuarios = []
            this.usuariosSetores.forEach(el => {
                if (el.set_id == this.atendimento.set_id) {
                    this.usuarios.push({
                        usr_id: el.usr_id,
                        usr_nome: el.usr_nome
                    })
                }
            })

            //Ordem alfabética (usuarios)
            this.usuarios.sort((a, b) => {
                return a.usr_nome < b.usr_nome ? -1 : a.usr_nome > b.usr_nome ? 1 : 0
            })
        }
    }
}
</script>

<style>

</style>