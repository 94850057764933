<template>
    <v-container class="setores-page" fluid>
        <v-layout align-center justify-center>
            <v-flex>
                <v-card>
                    <v-card-title>
                        <span class="text-h5"><strong>Setores</strong></span>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-text-field v-model="setor.set_nome" label="Nome" :color="azulPadrao"></v-text-field>
                            <v-checkbox v-model="setor.set_criar" label="O setor cria atendimentos?" :color="azulPadrao"></v-checkbox>
                            <v-checkbox v-model="setor.set_atualizar" label="O setor atualiza atendimentos?" :color="azulPadrao"></v-checkbox>
                            <v-checkbox v-model="setor.set_atualizar_outros" label="O setor atualiza atendimentos de outros usuários?" :color="azulPadrao"></v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="ml-2 mb-2" v-if="mode === 'save'" @click="save" :color="azulPadrao" dark>Salvar</v-btn>
                        <v-btn class="ml-2 mb-2" v-if="mode === 'remove'" @click="remove" :color="vermelhoPadrao" dark>Excluir</v-btn>
                        <v-btn class="ml-2 mb-2" @click="reset">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <br>
        <v-layout align-center justify-center>
            <v-flex>
                <v-card>
                    <v-card-title>
                        <span class="text-h5"><strong>Listagem</strong></span>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table hide-default-footer :headers="colunas" :items="setores" no-data-text="Nenhum setor cadastrado" :items-per-page="-1">
                            <template v-slot:[`item.set_criar`]="{ item }">
                                {{ formatarBoolTexto(item.set_criar) }}
                            </template>
                            <template v-slot:[`item.set_atualizar`]="{ item }">
                                {{ formatarBoolTexto(item.set_atualizar) }}
                            </template>
                            <template v-slot:[`item.set_atualizar_outros`]="{ item }">
                                {{ formatarBoolTexto(item.set_atualizar_outros) }}
                            </template>
                            <template v-slot:[`item.btnActions`]="{ item }">
                                <v-btn class="mr-2" @click="loadSetor(item)" x-small :color="azulPadrao" dark>Editar</v-btn>
                                <v-btn x-small @click="loadSetor(item, 'remove')" :color="vermelhoPadrao" dark>Remover</v-btn>
                            </template>
                        </v-data-table>
                        <div class="text-center">
                            <v-pagination v-model="page" :length="maxPage" v-if="maxPage > 1" :color="azulPadrao"></v-pagination>
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { azulPadrao, vermelhoPadrao, baseApiUrl, showError, formatarBoolTexto } from '@/config/global'
import axios from 'axios'

export default {
    name: 'SetoresPage',
    data() {
        return {
            azulPadrao,
            vermelhoPadrao,
            formatarBoolTexto,
            mode: 'save',
            page: 1,
            maxPage: 1,
            setor: {},
            setores: [],
            colunas: [
                {
                    text: 'Código',
                    value: 'set_id'
                },
                {
                    text: 'Nome',
                    value: 'set_nome'
                },
                {
                    text: 'Cria?',
                    value: 'set_criar'
                },
                {
                    text: 'Atualiza?',
                    value: 'set_atualizar'
                },
                {
                    text: 'Atualizar outros?',
                    value: 'set_atualizar_outros'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        loadSetores() {
            axios.get(`${baseApiUrl}/setores?page=${this.page}`)
                .then(r => {
                    this.setores = r.data.data
                    if (r.data.pagination > r.data.count) {
                        this.maxPage = 1
                    } else {
                        const i = r.data.count / r.data.pagination
                        if (Number.isInteger(i)) {
                            this.maxPage = i
                        } else {
                            this.maxPage = Math.trunc(i) +1
                        }
                    }
                })
                .catch(showError)
        },
        loadSetor(setor, mode = 'save') {
            this.mode = mode
            this.setor = { ...setor }
        },
        save() {
            const method = this.setor.set_id ? 'put' : 'post'
            const id = this.setor.set_id ? `/${this.setor.set_id}` : ''
            if (!this.setor.set_criar) {
                this.setor.set_criar = false
            }
            if (!this.setor.set_atualizar) {
                this.setor.set_atualizar = false
            }
            if (!this.setor.set_atualizar_outros) {
                this.setor.set_atualizar_outros = false
            }
            axios[method](`${baseApiUrl}/setores${id}`, this.setor)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        remove() {
            const id = this.setor.set_id
            axios.delete(`${baseApiUrl}/setores/${id}`)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        reset() {
            this.mode = 'save'
            this.setor = {}
            this.page = 1
            this.loadSetores()
        }
    },
    watch: {
        page() {
            this.loadSetores()
        }
    },
    mounted() {
        this.loadSetores()
    }
}
</script>

<style>

</style>