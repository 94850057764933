<template>
    <v-app-bar class="app-bar" app dark :color="azulPadrao">
        <img :src="require('@/assets/logo-menu.png')" height="40px" class="ml-5 d-none d-sm-flex">
        <router-link style="text-decoration: none; color: inherit;" :to="isUserVisible ? '/' : '/login'">
            <v-toolbar-title class="ml-5">Gerenciador de Atendimentos</v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>
        <UserDropdown v-if="isUserVisible" />
    </v-app-bar>
</template>

<script>
import UserDropdown from '@/components/templates/UserDropdown'
import { azulPadrao } from '@/config/global'
import { mapState } from 'vuex'

export default {
    name: 'Header',
    computed: mapState(['isUserVisible']),
    components: {
        UserDropdown
    },
    data() {
        return {
            azulPadrao
        }
    }
}
</script>

<style>

</style>