<template>
    <v-container class="configuracoes-page" fluid>
        <v-layout align-center justify-center>
            <v-flex>
                <v-card>
                    <v-card-title>
                        <span class="text-h5"><strong>Configurações</strong></span>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-text-field v-model="empresa.emp_id" label="Código" :color="azulPadrao" readonly></v-text-field>
                            <v-text-field v-model="empresa.emp_razao_social" label="Razão social" :color="azulPadrao" readonly></v-text-field>
                            <v-text-field v-model="empresa.emp_fantasia" label="Fantasia" :color="azulPadrao" readonly></v-text-field>
                            <v-text-field v-model="empresa.cnpj_formatado" label="CNPJ" :color="azulPadrao" readonly></v-text-field>
                            <v-text-field v-model="empresa.telefone_formatado" label="Telefone" :color="azulPadrao" readonly></v-text-field>
                            <v-text-field v-model="empresa.liberado_ate_formatado" label="Liberado até" :color="azulPadrao" readonly></v-text-field>
                            <v-text-field v-model="empresa.emp_max_usuarios" label="Usuários contratados" :color="azulPadrao" readonly></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-alert dense type="info" class="ml-2">
                            Caso deseja atualizar o plano atual da empresa, {{ msgContatoCodezz }}
                        </v-alert>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { azulPadrao, baseApiUrl, formatarData, msgContatoCodezz, formatarCnpjCpf, formatarTelefone, showError } from '@/config/global'
import axios from 'axios'

export default {
    name: 'ConfiguracoesPage',
    data() {
        return {
            azulPadrao,
            msgContatoCodezz,
            formatarCnpjCpf,
            empresa: {}
        }
    },
    mounted() {
        this.empresa = {}
        axios.get(`${baseApiUrl}/empresa`)
            .then(r => {
                this.empresa = r.data.data[0]
                this.empresa.cnpj_formatado = formatarCnpjCpf(this.empresa.emp_cnpj)
                this.empresa.telefone_formatado = formatarTelefone(this.empresa.emp_telefone)
                this.empresa.liberado_ate_formatado = formatarData(this.empresa.emp_liberado_ate)
            })
            .catch(showError)
    }
}
</script>

<style>

</style>