<template>
    <v-footer class="footer" app dark :color="azulPadrao">
        <v-row justify="center" class="text-center text-body-2">
            <v-col v-if="isUserVisible" cols="6">
                <span>Gerenciador de Atendimentos <strong>{{ user.emp_fantasia }}</strong></span>
            </v-col>
            <v-col cols="6">
                <span>Desenvolvido por <a href class="site-codezz" @click.prevent="openCodezz"><strong>Codezz Soluções em Tecnologia</strong></a></span>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
import { azulPadrao, urlSiteCodezz } from '@/config/global'
import { mapState } from 'vuex'

export default {
    name: 'Footer',
    computed: mapState(['user', 'isUserVisible']),
    data() {
        return {
            azulPadrao
        }
    },
    methods: {
        openCodezz() {
            window.open(urlSiteCodezz, '_blank').focus() //Abre o site em outra aba do navegador (_blank)
        }
    }
}
</script>

<style>
    .site-codezz {
        text-decoration: none;
    }

    .site-codezz strong {
        color: white;
    }
</style>