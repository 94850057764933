<template>
    <v-card class="criar-historico">
        <v-card-title>
            <span class="text-h5">Criar histórico</span>
        </v-card-title>
        <v-card-text>
            <v-form>
                <v-select v-model="statusAtendimento.sta_id" :items="status" item-value="sta_id" item-text="sta_descricao" label="Status" :color="azulPadrao"></v-select>
                <v-textarea v-model="statusAtendimento.std_observacao" label="Observação" :color="azulPadrao"></v-textarea>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn class="ml-2 mb-2" @click="save" :color="azulPadrao" dark>Salvar</v-btn>
            <v-btn class="ml-2 mb-2" @click="cancel" :color="vermelhoPadrao" dark>Fechar</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { azulPadrao, vermelhoPadrao, baseApiUrl, showError } from '@/config/global'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    //Esse componente é uma dialog, ele não é aberto individualmente em uma tela como todos os outros, ele é aberto sobre a tela principal do sistema (HomePage)...
    name: 'CriarStatusAtendimento',
    props: [
        'fecharDialog',
        'atendimentoId'
    ],
    computed: mapState(['user']),
    data() {
        return {
            azulPadrao,
            vermelhoPadrao,
            statusAtendimento: {},
            status: []
        }
    },
    methods: {
        save() {
            //Informa os campos fixos que não precisam ser informados pelo usuário mas são necessários para a inclusão do status (histórico)
            this.statusAtendimento.set_id = this.user.set_id
            this.statusAtendimento.usr_id = this.user.usr_id
            this.statusAtendimento.ate_id = this.atendimentoId

            //Faz a inclusão
            axios.post(`${baseApiUrl}/status_atendimento`, this.statusAtendimento)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.cancel()
                })
                .catch(showError)
        },
        cancel() {
            this.statusAtendimento = {}
            this.fecharDialog()
        }
    },
    mounted() {
        this.status = []
        axios.get(`${baseApiUrl}/status`)
            .then(r => {
                r.data.data.forEach(el => {
                    this.status.push(el)
                });

                //Ordem alfabética
                this.status.sort((a, b) => {
                    return a.sta_descricao < b.sta_descricao ? -1 : a.sta_descricao > b.sta_descricao ? 1 : 0
                })
            })
            .catch(showError)        
    }
}
</script>

<style>

</style>