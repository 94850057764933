<template>
    <v-container class="atendimentos-usuario">
        <PageTitle title="Atendimentos do usuário" subtitle="Usuário" :subtitleStrong="usuarioNome" />
        <v-expansion-panels v-model="panels" class="panels-atendimentos-usuario" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header class="pn-title" color="rgb(240, 240, 240)">
                    <strong>Atendimentos em aberto</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ListagemAtendimentos :lista="ateAbertos" :colunas="colAbertos" :destacarUrgente="true" />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header class="pn-title" color="rgb(240, 240, 240)">
                    <strong>Todos os atendimentos</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- Aqui não usa o componente ListagemAtendimentos pois deve ter paginação, então foi feito manualmente mesmo -->
                    <v-container fluid>
                        <v-layout align-center justify-center>
                            <v-flex>
                                <v-data-table hide-default-footer :headers="colTodos" :items="ateTodos" no-data-text="Nenhum atendimento" :items-per-page="-1">
                                    <template v-slot:[`item.ate_criacao`]="{ item }">
                                        {{ formatarDataHora(item.ate_criacao) }}
                                    </template>
                                    <template v-slot:[`item.ate_ultima_alteracao`]="{ item }">
                                        {{ formatarDataHora(item.ate_ultima_alteracao) }}
                                    </template>
                                    <template v-slot:[`item.ate_conclusao`]="{ item }">
                                        {{ formatarDataHora(item.ate_conclusao) }}
                                    </template>
                                    <template v-slot:[`item.btnActions`]="{ item }">
                                        <v-btn class="mr-2" @click="openAtendimento(item.ate_id)" x-small :color="azulPadrao" dark>Abrir</v-btn>
                                    </template>
                                </v-data-table>
                                <div class="text-center">
                                    <v-pagination v-model="page" :length="maxPage" v-if="maxPage > 1" :color="azulPadrao"></v-pagination>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-btn @click="atualizarAtendimentos" :color="azulPadrao" dark class="mt-4">Atualizar</v-btn>
    </v-container>
</template>

<script>
import PageTitle from '@/components/templates/PageTitle'
import ListagemAtendimentos from '@/components/others/ListagemAtendimentos'
import { azulPadrao, baseApiUrl, showError, formatarDataHora } from '@/config/global'
import axios from 'axios'

export default {
    name: 'AtendimentosUsuario',
    props: ['usuarioId', 'usuarioNome'],
    components: {
        PageTitle,
        ListagemAtendimentos
    },
    data() {
        return {
            azulPadrao,
            formatarDataHora,
            panels: [0, 1],
            ateAbertos: [],
            ateTodos: [],
            page: 1,
            maxPage: 1,
            colAbertos: [
                {
                    text: 'Código',
                    value: 'ate_id'
                },
                {
                    text: 'Título',
                    value: 'ate_titulo'
                },
                {
                    text: 'Cliente',
                    value: 'ate_cliente'
                },
                {
                    text: 'Urgente?',
                    value: 'ate_urgente'
                },
                {
                    text: 'Status',
                    value: 'sta_descricao'
                },
                {
                    text: 'Criação',
                    value: 'ate_criacao'
                },
                {
                    text: 'Última alteração',
                    value: 'ate_ultima_alteracao'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ],
            colTodos: [
                {
                    text: 'Código',
                    value: 'ate_id'
                },
                {
                    text: 'Título',
                    value: 'ate_titulo'
                },
                {
                    text: 'Cliente',
                    value: 'ate_cliente'
                },
                {
                    text: 'Status',
                    value: 'sta_descricao'
                },
                {
                    text: 'Criação',
                    value: 'ate_criacao'
                },
                {
                    text: 'Última alteração',
                    value: 'ate_ultima_alteracao'
                },
                {
                    text: 'Conclusão',
                    value: 'ate_conclusao'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        atualizarAtendimentos() {
            this.ateAbertos = []
            axios.get(`${baseApiUrl}/atendimentos_usuario_abertos/${this.usuarioId}`)
                .then(r => {
                    r.data.data.forEach(el => {
                        this.ateAbertos.push(el)
                    })
                })
                .catch(showError)

            this.ateTodos = []
            axios.get(`${baseApiUrl}/atendimentos_usuario/${this.usuarioId}?page=${this.page}`)
                .then(r => {
                    this.ateTodos = r.data.data
                    if (r.data.pagination > r.data.count) {
                        this.maxPage = 1
                    } else {
                        const i = r.data.count / r.data.pagination
                        if (Number.isInteger(i)) {
                            this.maxPage = i
                        } else {
                            this.maxPage = Math.trunc(i) +1
                        }
                    }
                })
                .catch(showError)
        },
        openAtendimento(atendimentoId) {
            this.$router.push({
                name: 'atendimentoPage',
                params: {
                    atendimentoId
                }
            })
        }
    },
    watch: {
        page() {
            this.atualizarAtendimentos()
        }
    },
    mounted() {
        if (this.usuarioId) {
            this.atualizarAtendimentos()
        } else {
            this.$router.push({
                name: 'homePage'
            })
        }
    }
}
</script>

<style>

</style>