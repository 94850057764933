import Vue from "vue"

const producaoCodezz = true

export const userKey = '__atendimentos_codezz'
export const baseApiUrl = producaoCodezz ? 'https://api-atendimentos.codezz.com.br:3000' : 'http://127.0.0.1:3000'
export const urlSiteCodezz = producaoCodezz ? 'https://www.codezz.com.br' : 'http://127.0.0.1:8081'

export const azulPadrao = 'rgb(92, 103, 184)'
export const vermelhoPadrao = 'rgb(232, 74, 82)'

export const urlBeep = require('@/assets/beep.mp3') //Com esse link 'https://www.soundjay.com/buttons/beep-09.mp3' a tv da ALFA não reproduziu som nenhum, não exatamente o pq...

export const msgContatoCodezz = 'entre em contato com a Codezz (codezz@codezz.com.br)'

export const segredoUsuario = 'Ud039UFYKA'
export const segredoAtendimento = 'NcPWD90Tn9'

export function showError(e) {
    if (e && e.response && e.response.data) {
        if (e.response.data.sqlMessage) {
            Vue.toasted.global.msgError({ msg: e.response.data.sqlMessage})
        } else {
            Vue.toasted.global.msgError({ msg: e.response.data })
        }
    } else if (typeof e === 'string') {
        Vue.toasted.global.msgError({ msg: e })
    } else {
        Vue.toasted.global.msgError()
    }
}

export function formatarBoolTexto(b) {
    return b ? 'Sim' : 'Não'
}

export function formatarData(d) {
    if (d) {
        const data = new Date(d)
        return String(data.getDate()).padStart(2, '0') + "/" +
            String(data.getMonth() + 1).padStart(2, '0') + "/" +
            String(data.getFullYear())
    } else {
        return ''
    }
}

export function formatarDataHora(d) {
    if (d) {
        const data = new Date(d)
        return String(data.getDate()).padStart(2, '0') + "/" +
            String(data.getMonth() + 1).padStart(2, '0') + "/" +
            String(data.getFullYear()) + " " + 
            String(data.getHours()).padStart(2, '0') + ":" +
            String(data.getMinutes()).padStart(2, '0')
    } else {
        return ''
    }
}

export function formatarCnpjCpf(s) {
    if (s.length <= 11) {
        return s.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4") // eslint-disable-line
    } else {
        return s.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5") // eslint-disable-line
    }
}

export function formatarTelefone(s) {
    let num = s
    num = num.replace(/\D/g,"") //Remove tudo o que não é dígito
    num = num.replace(/^(\d{2})(\d)/g,"($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
    num = num.replace(/(\d)(\d{4})$/,"$1-$2") //Coloca hífen entre o quarto e o quinto dígitos
    return num
}

export default {
    userKey,
    baseApiUrl,
    azulPadrao,
    vermelhoPadrao,
    urlBeep,
    msgContatoCodezz,
    segredoUsuario,
    segredoAtendimento,
    showError,
    formatarBoolTexto,
    formatarData,
    formatarDataHora,
    formatarCnpjCpf,
    formatarTelefone
}
