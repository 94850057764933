<template>
    <v-main>
        <v-container>
            <router-view></router-view>
        </v-container>
    </v-main>
</template>

<script>
export default {
    name: 'Content'
}
</script>

<style>

</style>