<template>
    <div class="home-page">
        <v-alert v-if="mostrarBloqueio" dense type="warning">
            Atenção, a empresa será bloqueada {{ diasBloqueio == 0 ? 'hoje' : diasBloqueio == 1 ? 'em 1 dia' : 'em ' + diasBloqueio + ' dias' }}, {{ msgContatoCodezz }}
        </v-alert>

        <PageTitle v-if="isUserVisible" title="Atendimentos" subtitle="Atendimentos do usuário" :subtitleStrong="user.usr_nome" />
        
        <v-expansion-panels v-if="!tokenExpirado && !empresaBloqueada" v-model="panels" class="panels-home-page" multiple>
            <v-expansion-panel v-if="user.usr_admin || user.set_atualizar">
                <v-expansion-panel-header class="pn-title" color="rgb(240, 240, 240)">
                    <strong>Atendimentos em aberto</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ListagemAtendimentos :lista="ateAbertos" :colunas="colAbertos" :destacarUrgente="true" />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="user.usr_admin || user.set_atualizar">
                <v-expansion-panel-header class="pn-title" color="rgb(240, 240, 240)">
                    <strong>Atendimentos concluídos</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ListagemAtendimentos :lista="ateConcluidos" :colunas="colConcluidos" :destacarUrgente="false" />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="user.usr_admin || user.set_criar">
                <v-expansion-panel-header class="pn-title" color="rgb(240, 240, 240)">
                    <strong>Atendimentos criados</strong>
                    <v-spacer></v-spacer>

                    <v-btn @click="dialog = true" @click.native.stop class="ml-4" icon max-width="40" x-small>
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-dialog v-model="dialog" persistent max-width="600px">
                        <CriarAtendimento :fecharDialog="fecharDialog" />
                    </v-dialog>

                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ListagemAtendimentos :lista="ateCriados" :colunas="colCriados" :destacarUrgente="false" />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-alert v-else-if="empresaBloqueada" dense type="error">
            Empresa bloqueada, {{ msgContatoCodezz }}
        </v-alert>
        <v-alert v-else dense type="warning">
            Token do usuário expirado, atualize a página e faça login novamente.
        </v-alert>

        <div v-if="!tokenExpirado && !empresaBloqueada && (user.usr_admin || user.set_atualizar)">
            <v-btn class="mt-5" @click="atualizarAtendimentos(false)" :color="azulPadrao" dark>Atualizar</v-btn>
            <v-checkbox class="mt-2" v-model="avisoSonoro" label="Aviso sonoro?" :color="azulPadrao"></v-checkbox>
        </div>
    </div>
</template>

<script>
import PageTitle from '@/components/templates/PageTitle'
import ListagemAtendimentos from '@/components/others/ListagemAtendimentos'
import CriarAtendimento from '@/components/others/CriarAtendimento'
import { mapState } from 'vuex'
import { azulPadrao, baseApiUrl, urlBeep, msgContatoCodezz, showError } from '@/config/global'
import axios from 'axios'

export default {
    name: "HomePage",
    components: {
        PageTitle,
        ListagemAtendimentos,
        CriarAtendimento
    },
    computed: mapState(['user', 'isUserVisible']),
    data() {
        return {
            azulPadrao,
            msgContatoCodezz,
            panels: [0, 1, 2], //Assim todos os panels iniciam abertos
            ateAbertos: [],
            ateConcluidos: [],
            ateCriados: [],
            dialog: false,
            empresaBloqueada: false,
            mostrarBloqueio: false,
            tokenExpirado: false,
            diasBloqueio: 0,
            maxAtendimento: 0,
            avisoSonoro: false,
            colAbertos: [
                {
                    text: 'Código',
                    value: 'ate_id'
                },
                {
                    text: 'Título',
                    value: 'ate_titulo'
                },
                {
                    text: 'Cliente',
                    value: 'ate_cliente'
                },
                {
                    text: 'Urgente?',
                    value: 'ate_urgente'
                },
                {
                    text: 'Status',
                    value: 'sta_descricao'
                },
                {
                    text: 'Criação',
                    value: 'ate_criacao'
                },
                {
                    text: 'Última alteração',
                    value: 'ate_ultima_alteracao'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ],
            colConcluidos: [
                {
                    text: 'Código',
                    value: 'ate_id'
                },
                {
                    text: 'Título',
                    value: 'ate_titulo'
                },
                {
                    text: 'Cliente',
                    value: 'ate_cliente'
                },
                {
                    text: 'Status',
                    value: 'sta_descricao'
                },
                {
                    text: 'Criação',
                    value: 'ate_criacao'
                },
                {
                    text: 'Conclusão',
                    value: 'ate_conclusao'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ],
            colCriados: [
                {
                    text: 'Código',
                    value: 'ate_id'
                },
                {
                    text: 'Título',
                    value: 'ate_titulo'
                },
                {
                    text: 'Cliente',
                    value: 'ate_cliente'
                },
                {
                    text: 'Usuário',
                    value: 'usr_nome'
                },
                {
                    text: 'Status',
                    value: 'sta_descricao'
                },
                {
                    text: 'Criação',
                    value: 'ate_criacao'
                },
                {
                    text: 'Última alteração',
                    value: 'ate_ultima_alteracao'
                },
                {
                    text: 'Conclusão',
                    value: 'ate_conclusao'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        atualizarAtendimentos(timer) {
            //Atendimentos para o usuário (rota sem paginação)
            this.tokenExpirado = false
            this.ateAbertos = []
            this.ateConcluidos = []
            if (this.user.usr_admin || this.user.set_atualizar) {
                axios.get(`${baseApiUrl}/atendimentos`)
                    .then(r => {
                        r.data.data.forEach(el => {
                            if (el.ate_conclusao) {
                                this.ateConcluidos.push(el)
                            } else {
                                this.ateAbertos.push(el)
                                
                                //Atualiza o último atendimento (pelo código, que sempre vai aumentando. Emite um beep sonoro se estiver marcado pra avisar)
                                if (el.ate_id > this.maxAtendimento) {
                                    this.maxAtendimento = el.ate_id
                                    
                                    if (timer && this.avisoSonoro) {
                                        let audio = new Audio(urlBeep)
                                        audio.play()
                                    }
                                }
                            }
                        })
                    })
                    .catch(e => {
                        showError(e)
                        if (e.response.status == 401) {
                            this.tokenExpirado = true
                        }
                    })
            }

            //Quando o atualizar é chamado diretamente pelo timer, não precisa atualizar os atendimentos criados
            if (!timer) {
                //Atendimentos criados pelo usuário (rota sem paginação)
                this.ateCriados = []
                if (this.user.usr_admin || this.user.set_criar) {
                    axios.get(`${baseApiUrl}/atendimentos_criados`)
                        .then(r => {
                            r.data.data.forEach(el => {
                                this.ateCriados.push(el)
                            })
                        })
                        .catch(showError)
                }
            }

            //Atualiza os dias que falta pra empresa ser bloqueada, caso ela não esteja liberada
            this.empresaBloqueada = false
            this.mostrarBloqueio = false
            this.diasBloqueio = 0
            axios.put(`${baseApiUrl}/empresa_liberada_aberta`, { emp_id: this.user.emp_id })
                .then(r => {
                    if (!r.data.status) {
                        if (r.data.bloqueada) {
                            this.empresaBloqueada = true
                        } else {
                            this.mostrarBloqueio = true
                        }

                        const d1 = new Date(r.data.emp_liberado_ate)
                        const d2 = new Date()
                        d1.setHours(0, 0, 0, 0)
                        d2.setHours(0, 0, 0, 0)
                        this.diasBloqueio = 5 - ((d2 - d1) / (1000 * 3600 * 24))
                    }
                })
                .catch(showError)
        },
        fecharDialog() {
            this.dialog = false
            this.atualizarAtendimentos(false)
        }
    },
    mounted() {
        this.atualizarAtendimentos(false)
        
        //Atualiza os atendimentos de 2,5 em 2,5 minutos (faz em um intervalo maior pois a tela de monitoramento já atualiza de minuto em minuto, e teoricamente ela é sempre usada)
        window.setInterval(() => {
            this.atualizarAtendimentos(true)
        }, 150000) //150000 milisegundos = 2,5 minutos
    }
}
</script>

<style>
    .pn-title {
        color: rgb(92, 103, 184); /* é a mesma cor que "azulPadrao" de "config/global.js" */
        text-decoration: bold;
    }
</style>