var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"atendimentos-usuario"},[_c('PageTitle',{attrs:{"title":"Atendimentos do usuário","subtitle":"Usuário","subtitleStrong":_vm.usuarioNome}}),_c('v-expansion-panels',{staticClass:"panels-atendimentos-usuario",attrs:{"multiple":""},model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pn-title",attrs:{"color":"rgb(240, 240, 240)"}},[_c('strong',[_vm._v("Atendimentos em aberto")])]),_c('v-expansion-panel-content',[_c('ListagemAtendimentos',{attrs:{"lista":_vm.ateAbertos,"colunas":_vm.colAbertos,"destacarUrgente":true}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pn-title",attrs:{"color":"rgb(240, 240, 240)"}},[_c('strong',[_vm._v("Todos os atendimentos")])]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.colTodos,"items":_vm.ateTodos,"no-data-text":"Nenhum atendimento","items-per-page":-1},scopedSlots:_vm._u([{key:"item.ate_criacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarDataHora(item.ate_criacao))+" ")]}},{key:"item.ate_ultima_alteracao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarDataHora(item.ate_ultima_alteracao))+" ")]}},{key:"item.ate_conclusao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarDataHora(item.ate_conclusao))+" ")]}},{key:"item.btnActions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","color":_vm.azulPadrao,"dark":""},on:{"click":function($event){return _vm.openAtendimento(item.ate_id)}}},[_vm._v("Abrir")])]}}],null,true)}),_c('div',{staticClass:"text-center"},[(_vm.maxPage > 1)?_c('v-pagination',{attrs:{"length":_vm.maxPage,"color":_vm.azulPadrao},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)],1)],1)],1)],1)],1),_c('v-btn',{staticClass:"mt-4",attrs:{"color":_vm.azulPadrao,"dark":""},on:{"click":_vm.atualizarAtendimentos}},[_vm._v("Atualizar")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }