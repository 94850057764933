<template>
    <v-main>
        <v-container class="monitorar-page" fluid>
            <v-layout class="tabela-ultimos" align-center justify-center>
                <v-flex>
                    <v-card class="ma-1">
                        <v-card-title>
                            <span class="text-h5"><strong>Últimos atendimentos</strong></span>
                            <v-spacer></v-spacer>
                            <v-checkbox v-model="avisoSonoro" label="Aviso sonoro?" :color="azulPadrao" class="ma-0"></v-checkbox>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :item-class="corFonteUrgente" hide-default-footer :headers="colunas" :items="ultimosAtendimentos" :no-data-text="msgAtendimento" :items-per-page="-1">
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
            <br>

            <!-- Listagem dos atendimentos em aberto separados por setor -->
            <v-layout v-if="user.usr_separar_setores" class="tabela-setores" align-center justify-center>
                <v-flex>
                    <v-carousel cycle hide-delimiters show-arrows-on-hover height="100%">
                        <v-carousel-item v-for="(ate, i) in atendimentosSetores" :key="i">
                            <v-card class="ma-1">
                                <v-card-title>
                                    <span class="text-h5"><strong>Atendimentos do setor {{ ate.set_nome }}</strong></span>
                                </v-card-title>
                                <v-card-text>
                                    <v-data-table :item-class="corFonteUrgente" hide-default-footer :headers="colunas" :items="ate.atendimentos" :no-data-text="msgAtendimento" :items-per-page="-1">
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-carousel-item> 
                    </v-carousel>
                </v-flex>
            </v-layout>

            <!-- Listagem dos atendimentos em aberto -->
            <v-layout v-else class="tabela-abertos" align-center justify-center>
                <v-flex>
                    <v-card class="ma-1">
                        <v-card-title>
                            <span class="text-h5"><strong>Atendimentos em aberto</strong></span>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :item-class="corFonteUrgente" hide-default-footer :headers="colunas" :items="atendimentos" :no-data-text="msgAtendimento" :items-per-page="-1">
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-main>
</template>

<script>
import { azulPadrao, baseApiUrl, urlBeep, showError, formatarData, formatarBoolTexto } from '@/config/global'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'Monitorar',
    computed: mapState(['user', 'isUserVisible']),
    data() {
        return {
            azulPadrao,
            formatarData,
            formatarBoolTexto,
            avisoSonoro: false,
            maxAtendimento: 0,
            ultimosAtendimentos: [],
            atendimentos: [],
            atendimentosSetores: [],
            msgAtendimento: "Nenhum atendimento em aberto",
            colunas: [
                {
                    text: 'Código',
                    value: 'ate_id'
                },
                {
                    text: 'Setor',
                    value: 'set_nome'
                },
                {
                    text: 'Usuário',
                    value: 'usr_nome'
                },
                {
                    text: 'Título',
                    value: 'ate_titulo'
                },
                {
                    text: 'Cliente',
                    value: 'ate_cliente'
                },
                {
                    text: 'Tempo (horas)',
                    value: 'tempo_aberto'
                }
            ]
        }
    },
    methods: {
        getHoursBetween(d) {
            //Retorna a quantidade de horas (hh:mm) entre dois DateTime (parâmetro e data atual)
            let today = new Date()
            let pData = new Date(d)
            let diffMs = (today - pData) //milisegundos
            let diffDays = Math.floor(diffMs / 86400000); //Dias
            const horas = parseFloat((diffDays * 24) + ((diffMs % 86400000) / 3600000)).toFixed(2)
            const minuto = Math.trunc(parseFloat((horas % 1) * 60))
            
            return Math.trunc(horas) + ':' + String(minuto).padStart(2, '0')
        },
        async atualizarAtendimentos(apitar) {
            //Esse if do ususário é pra evitar ficar consumindo a API depois de fazer logout por exemplo... Apenas por segurança
            if (this.isUserVisible && this.user && this.user.usr_monitorar) {
                
                //Últimos atendimentos
                this.ultimosAtendimentos = []
                await axios.get(`${baseApiUrl}/ultimos_atendimentos`)
                    .then(r => {
                        this.ultimosAtendimentos = r.data.data
                        this.ultimosAtendimentos.forEach(el => {
                            el.tempo_aberto = this.getHoursBetween(el.ate_ultima_alteracao)
                        })
                    })
                    .catch(showError)

                //Todos os atendimentos em aberto
                this.atendimentos = []
                await axios.get(`${baseApiUrl}/monitorar_atendimentos`)
                    .then(r => {
                        this.atendimentos = r.data.data
                        this.atendimentos.forEach(el => {
                            el.tempo_aberto = this.getHoursBetween(el.ate_ultima_alteracao)
                        })

                        if (this.user.usr_separar_setores) {
                            //Ordem os atendimentos em aberto pelo código do setor
                            this.atendimentos.sort((a, b) => {
                                return a.set_id < b.set_id ? -1 : a.set_id > b.set_id ? 1 : 0
                            })

                            let setAtual = 0
                            let setObj = {}
                            this.atendimentosSetores = []
                            this.atendimentos.forEach(el => {
                                if (setAtual != el.set_id) {
                                    if (setAtual > 0) {
                                        this.atendimentosSetores.push(setObj)
                                    }
                                    
                                    setAtual = el.set_id
                                    
                                    setObj = {}
                                    setObj.set_id = el.set_id
                                    setObj.set_nome = el.set_nome
                                    setObj.atendimentos = []
                                }

                                setObj.atendimentos.push(el)
                            })
                            
                            this.atendimentosSetores.push(setObj) //Adiciona o último setor
                        }
                    })
                    .catch(showError)

                let firstAteLista = this.ultimosAtendimentos[0].ate_id
                if (firstAteLista > this.maxAtendimento) {
                    this.maxAtendimento = firstAteLista
                        
                    //Aviso sonoro
                    //Precisa ter o check do aviso sonoro na tela pois segundo as políticas do google, o usuário precisa interagir na tela pro site "ter permissão" de reproduzir a necessidade
                    //Fonte: https://stackoverflow.com/questions/58846042/getting-play-failed-because-the-user-didnt-interact-with-the-document-first
                    
                    if (apitar && this.avisoSonoro) {
                        let audio = new Audio(urlBeep)
                        audio.play()
                    }
                }
            }
        },
        corFonteUrgente(item) {
            return item.ate_urgente ? 'cor-destacada' : 'cor-normal'
        }
    },
    mounted() {
        this.atualizarAtendimentos(false)

        window.setInterval(() => {
            this.atualizarAtendimentos(true)
        }, 60000) //Atualiza os atendimentos de minuto em minuto (60000 milisegundos)
    }
}
</script>

<style>
    .tabela-ultimos tbody td {
        font-size: 22px !important;
    }
    
    .tabela-ultimos thead th {
        font-size: 15px !important;
    }

    .tabela-abertos tbody td {
        font-size: 22px !important;
    }
    
    .tabela-abertos thead th {
        font-size: 15px !important;
    }

    .tabela-setores tbody td {
        font-size: 22px !important;
    }
    
    .tabela-setores thead th {
        font-size: 15px !important;
    }

    .cor-normal {
        color: black;
    }

    .cor-destacada {
        color: rgb(232, 74, 82);
        font-weight: bold;
    }
</style>