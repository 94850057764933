<template>
    <div class="login-page">
        <PageTitle title="Login" subtitle="Informe um e-mail e senha para entrar no sistema" />
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md4 xl3>
                    <v-card elevation="4">
                        <v-card-text>
                            <v-form ref="form" v-model="valid">
                                <v-text-field v-model="user.email" label="E-mail" :rules="mailRule" required :color="azulPadrao"></v-text-field>
                                <v-text-field v-model="user.senha" label="Senha" type="password" :rules="passwordRule" required :color="azulPadrao"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn class="ml-2 mb-2" @click="login" :color="azulPadrao" dark>Entrar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="mr-2 mb-2" @click="openNovaEmpresa" :color="azulPadrao" dark outlined>Cadastre-se</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import PageTitle from '@/components/templates/PageTitle'
import { azulPadrao, baseApiUrl, userKey, showError } from '@/config/global'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'LoginPage',
    computed: mapState(['isUserVisible']),
    components: {
        PageTitle
    },
    data() {
        return {
            azulPadrao,
            valid: false,
            user: {},
            mailRule: [
                x => !!x || 'E-mail deve ser informado'
            ],
            passwordRule: [
                x => !!x || 'Senha deve ser informada'
            ]
        }
    },
    methods: {
        login() {
            this.$refs.form.validate()
            if (this.valid) {
                axios.put(`${baseApiUrl}/login`, this.user)
                    .then(res => {
                        this.$store.commit('setUser', res.data)
                        localStorage.setItem(userKey, JSON.stringify(res.data))
                        this.$router.push({
                            name: 'homePage'
                        })
                    })
                    .catch(showError)
            }
        },
        openNovaEmpresa() {
            this.$router.push({
                name: 'novaEmpresa'
            })
        }
    },
    mounted() {
        //Assim obriga o usuário fazer logoff antes de abrir a página de login
        if (this.isUserVisible) {
            this.$router.push({
                name: 'homePage'
            })
        }
    }
}
</script>

<style>

</style>