<template>
    <v-container class="status-page" fluid>
        <v-layout align-center justify-center>
            <v-flex>
                <v-card>
                    <v-card-title>
                        <span class="text-h5"><strong>Status</strong></span>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-text-field v-model="stat.sta_descricao" label="Descrição" :color="azulPadrao"></v-text-field>
                            <v-checkbox v-model="stat.sta_final" label="O status encerra o atendimento?" :color="azulPadrao"></v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="ml-2 mb-2" v-if="mode === 'save'" @click="save" :color="azulPadrao" dark>Salvar</v-btn>
                        <v-btn class="ml-2 mb-2" v-if="mode === 'remove'" @click="remove" :color="vermelhoPadrao" dark>Excluir</v-btn>
                        <v-btn class="ml-2 mb-2" @click="reset">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <br>
        <v-layout align-center justify-center>
            <v-flex>
                <v-card>
                    <v-card-title>
                        <span class="text-h5"><strong>Listagem</strong></span>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table hide-default-footer :headers="colunas" :items="status" no-data-text="Nenhum status cadastrado" :items-per-page="-1">
                            <template v-slot:[`item.sta_final`]="{ item }">
                                {{ formatarBoolTexto(item.sta_final) }}
                            </template>
                            <template v-slot:[`item.btnActions`]="{ item }">
                                <v-btn class="mr-2" @click="loadStat(item)" x-small :color="azulPadrao" dark>Editar</v-btn>
                                <v-btn x-small @click="loadStat(item, 'remove')" :color="vermelhoPadrao" dark>Remover</v-btn>
                            </template>
                        </v-data-table>
                        <div class="text-center">
                            <v-pagination v-model="page" :length="maxPage" v-if="maxPage > 1" :color="azulPadrao"></v-pagination>
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { azulPadrao, vermelhoPadrao, baseApiUrl, showError, formatarBoolTexto } from '@/config/global'
import axios from 'axios'

export default {
    name: 'StatusPage',
    data() {
        return {
            azulPadrao,
            vermelhoPadrao,
            formatarBoolTexto,
            mode: 'save',
            page: 1,
            maxPage: 1,
            stat: {},
            status: [],
            colunas: [
                {
                    text: 'Código',
                    value: 'sta_id'
                },
                {
                    text: 'Descrição',
                    value: 'sta_descricao'
                },
                {
                    text: 'Final?',
                    value: 'sta_final'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        loadStatus() {
            axios.get(`${baseApiUrl}/status?page=${this.page}`)
                .then(r => {
                    this.status = r.data.data
                    if (r.data.pagination > r.data.count) {
                        this.maxPage = 1
                    } else {
                        const i = r.data.count / r.data.pagination
                        if (Number.isInteger(i)) {
                            this.maxPage = i
                        } else {
                            this.maxPage = Math.trunc(i) +1
                        }
                    }
                })
                .catch(showError)
        },
        loadStat(stat, mode = 'save') {
            this.mode = mode
            this.stat = { ...stat }
        },
        save() {
            const method = this.stat.sta_id ? 'put' : 'post'
            const id = this.stat.sta_id ? `/${this.stat.sta_id}` : ''
            if (!this.stat.sta_final) {
                this.stat.sta_final = false
            }
            axios[method](`${baseApiUrl}/status${id}`, this.stat)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        remove() {
            const id = this.stat.sta_id
            axios.delete(`${baseApiUrl}/status/${id}`)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        reset() {
            this.mode = 'save'
            this.stat = {}
            this.page = 1
            this.loadStatus()
        }
    },
    watch: {
        page() {
            this.loadStatus()
        }
    },
    mounted() {
        this.loadStatus()
    }
}
</script>

<style>

</style>