<template>
    <v-container class="usuarios-page" fluid>
        <v-layout align-center justify-center>
            <v-flex>
                <v-card>
                    <v-card-title>
                        <span class="text-h5"><strong>Usuários</strong></span>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-text-field v-model="usuario.usr_nome" label="Nome" :color="azulPadrao"></v-text-field>
                            <v-text-field v-model="usuario.usr_email" label="E-mail" :color="azulPadrao"></v-text-field>
                            <v-text-field v-model="usuario.usr_senha" label="Senha" type="password" :color="azulPadrao"></v-text-field>
                            <v-text-field v-model="usuario.usr_senha_confirmacao" label="Confirmação de senha" type="password" :color="azulPadrao"></v-text-field>
                            <v-select v-model="usuario.set_id" :items="setores" item-value="set_id" item-text="set_nome" label="Setor" :color="azulPadrao"></v-select>
                            <v-checkbox v-model="usuario.usr_admin" label="O usuário é administrador?" :color="azulPadrao"></v-checkbox>
                            <v-checkbox v-model="usuario.usr_monitorar" label="Será usado para monitorar os atendimentos?" :color="azulPadrao"></v-checkbox>
                            <v-checkbox v-if="usuario.usr_monitorar" v-model="usuario.usr_separar_setores" label="Separar o monitoramento por setores?" :color="azulPadrao"></v-checkbox>
                            <v-checkbox v-model="usuario.usr_ativo" label="O usuário está ativo?" :color="azulPadrao"></v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="ml-2 mb-2" @click="save" :color="azulPadrao" dark>Salvar</v-btn>
                        <v-btn class="ml-2 mb-2" @click="reset">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <br>
        <v-layout align-center justify-center>
            <v-flex>
                <v-card>
                    <v-card-title>
                        <span class="text-h5"><strong>Listagem</strong></span>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table hide-default-footer :headers="colunas" :items="usuarios" no-data-text="Nenhum usuário cadastrado" :items-per-page="-1">
                            <template v-slot:[`item.usr_admin`]="{ item }">
                                {{ formatarBoolTexto(item.usr_admin) }}
                            </template>
                            <template v-slot:[`item.usr_ativo`]="{ item }">
                                {{ formatarBoolTexto(item.usr_ativo) }}
                            </template>
                            <template v-slot:[`item.usr_monitorar`]="{ item }">
                                {{ formatarBoolTexto(item.usr_monitorar) }}
                            </template>
                            <template v-slot:[`item.usr_separar_setores`]="{ item }">
                                {{ formatarBoolTexto(item.usr_separar_setores) }}
                            </template>
                            <template v-slot:[`item.btnActions`]="{ item }">
                                <v-btn class="mr-2" @click="loadUsuario(item)" x-small :color="azulPadrao" dark>Editar</v-btn>
                                <v-btn class="mr-2" @click="openAtendimentosUsuario(item.usr_id, item.usr_nome)" x-small :color="azulPadrao" dark>Atendimentos</v-btn>
                                <v-btn class="mr-2" @click="gerarCodigoUser(item.set_id, item.usr_id, item.usr_nome)" x-small :color="azulPadrao" dark>Código</v-btn>
                            </template>
                        </v-data-table>
                        <div class="text-center">
                            <v-pagination v-model="page" :length="maxPage" v-if="maxPage > 1" :color="azulPadrao"></v-pagination>
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog v-model="dialogCodigo" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Código do usuário <strong>{{ nomeUsuario }}</strong></span>
                </v-card-title>
                <v-card-text>
                    {{ codigoUsuario }}
                </v-card-text>
                <v-card-actions>
                    <v-btn class="ml-2 mb-2" @click="dialogCodigo = false" :color="azulPadrao" dark>Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { azulPadrao, baseApiUrl, segredoUsuario, showError, formatarBoolTexto } from '@/config/global'
import { mapState } from 'vuex'
import axios from 'axios'
import jwt from 'jwt-simple'

export default {
    name: 'UsuariosPage',
    computed: mapState(['user']),
    data() {
        return {
            azulPadrao,
            formatarBoolTexto,
            page: 1,
            maxPage: 1,
            usuario: {},
            usuarios: [],
            setores: [],
            dialogCodigo: false,
            codigoUsuario: '',
            nomeUsuario: '',
            colunas: [
                {
                    text: 'Código',
                    value: 'usr_id'
                },
                {
                    text: 'Nome',
                    value: 'usr_nome'
                },
                {
                    text: 'E-mail',
                    value: 'usr_email'
                },
                {
                    text: 'Setor',
                    value: 'set_nome'
                },
                {
                    text: 'Admin?',
                    value: 'usr_admin'
                },
                {
                    text: 'Monitorar?',
                    value: 'usr_monitorar'
                },
                {
                    text: 'Setores?',
                    value: 'usr_separar_setores'
                },
                {
                    text: 'Ativo?',
                    value: 'usr_ativo'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        loadUsuarios() {
            axios.get(`${baseApiUrl}/usuarios?page=${this.page}`)
                .then(r => {
                    this.usuarios = r.data.data
                    if (r.data.pagination > r.data.count) {
                        this.maxPage = 1
                    } else {
                        const i = r.data.count / r.data.pagination
                        if (Number.isInteger(i)) {
                            this.maxPage = i
                        } else {
                            this.maxPage = Math.trunc(i) +1
                        }
                    }
                })
                .catch(showError)
        },
        async loadSetores() {
            this.setores = []
            let pg = 0
            let continuar = true

            while (continuar) {
                pg++
                await axios.get(`${baseApiUrl}/setores?page=${pg}`)
                    .then(r => {
                        if (r.data.data.length == 0) {
                            continuar = false
                        } else {
                            r.data.data.forEach(el => {
                                this.setores.push(el)
                            });
                        }
                    })
                    .catch(e => {
                        continuar = false
                        showError(e)
                    })
            }

            //Ordena em ordem alfabética pra facilitar pro usuário
            this.setores.sort((a, b) => {
                return a.set_nome < b.set_nome ? -1 : a.set_nome > b.set_nome ? 1 : 0
            })
        },
        loadUsuario(usuario) {
            this.usuario = { ...usuario }
            delete this.usuario.set_nome //Deleta o nome do setor pois o campo não existe na tabela de usuários
        },
        save() {
            const method = this.usuario.usr_id ? 'put' : 'post'
            const id = this.usuario.usr_id ? `/${this.usuario.usr_id}` : ''
            if (!this.usuario.usr_admin) {
                this.usuario.usr_admin = false
            }
            if (!this.usuario.usr_monitorar) {
                this.usuario.usr_monitorar = false
            }
            if (!this.usuario.usr_separar_setores) {
                this.usuario.usr_separar_setores = false
            }
            if (!this.usuario.usr_ativo) {
                this.usuario.usr_ativo = false
            }
            axios[method](`${baseApiUrl}/usuarios${id}`, this.usuario)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        remove() {
            const id = this.usuario.usr_id
            axios.delete(`${baseApiUrl}/usuarios/${id}`)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        reset() {
            this.usuario = {}
            this.page = 1
            this.loadUsuarios()
            this.loadSetores()
        },
        openAtendimentosUsuario(usuarioId, usuarioNome) {
            this.$router.push({
                name: 'atendimentosUsuario',
                params: {
                    usuarioId,
                    usuarioNome
                }
            })
        },
        gerarCodigoUser(set, usr, usrNome) {
            const objUsr = {
                emp_id: this.user.emp_id,
                set_id: set,
                usr_id: usr
            }

            this.codigoUsuario = jwt.encode(objUsr, segredoUsuario)
            this.nomeUsuario = usrNome
            this.dialogCodigo = true
        }
    },
    watch: {
        page() {
            this.loadUsuarios()
        }
    },
    mounted() {
        this.loadUsuarios()
        this.loadSetores()
    }
}
</script>

<style>

</style>