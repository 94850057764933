var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"atendimentos-admin-page",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',[_c('v-data-table',{staticClass:"listagem-atendimentos",attrs:{"item-class":_vm.corFonteUrgente,"hide-default-footer":"","headers":_vm.colunas,"items":_vm.lista,"no-data-text":"Nenhum atendimento","items-per-page":-1},scopedSlots:_vm._u([{key:"item.ate_urgente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarBoolTexto(item.ate_urgente))+" ")]}},{key:"item.ate_criacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarDataHora(item.ate_criacao))+" ")]}},{key:"item.ate_ultima_alteracao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarDataHora(item.ate_ultima_alteracao))+" ")]}},{key:"item.ate_conclusao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarDataHora(item.ate_conclusao))+" ")]}},{key:"item.btnActions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","color":_vm.azulPadrao,"dark":""},on:{"click":function($event){return _vm.openAtendimento(item.ate_id)}}},[_vm._v("Abrir")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }