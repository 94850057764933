<template>
    <div class="page-title">
        <br>
        <span class="text-h5"><strong>{{ title }}</strong></span>
        <p>{{ subtitle }} <strong>{{ subtitleStrong }}</strong></p>
        <hr>
        <br>
    </div>
</template>

<script>
export default {
    name: 'PageTitle',
    props: [
        'title',
        'subtitle',
        'subtitleStrong'
    ]
}
</script>

<style>

</style>