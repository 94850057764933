<template>
    <div class="criar-atendimento-cliente">
        <PageTitle title="Criar um atendimento" />
        <v-container fluid fill-height>
            <v-layout v-if="!codigoValido" align-center justify-center>
                <v-flex xs12 sm10 md8 lg6>
                    <v-card elevation="4">
                        <v-card-title>
                            <span class="text-h6">Informe o código pra identificar a empresa e o usuário</span>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field v-model="codigoUser" label="Código" :color="azulPadrao"></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn class="ml-2 mb-2" @click="validarCodigo" :color="azulPadrao" dark>Validar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-layout v-if="codigoValido" align-center justify-center>
                <v-flex xs12 sm10 md8 lg6>
                    <v-card elevation="4">
                        <v-card-title>
                            <span class="text-h6">Criar o atendimento</span>
                        </v-card-title>
                        <v-card-text>
                            <v-form>
                                <v-select v-model="atendimento.emp_id" :items="empresas" item-value="emp_id" item-text="emp_razao_social" label="Empresa" :color="azulPadrao"></v-select>
                                <v-select v-model="atendimento.set_id" :items="setores" item-value="set_id" item-text="set_nome" label="Setor" :color="azulPadrao"></v-select>
                                <v-select v-model="atendimento.usr_id" :items="usuarios" item-value="usr_id" item-text="usr_nome" label="Usuário" :color="azulPadrao"></v-select>
                                <v-text-field v-model="atendimento.ate_titulo" label="Título" :color="azulPadrao"></v-text-field>
                                <v-text-field v-model="atendimento.ate_cliente" label="Cliente" :color="azulPadrao"></v-text-field>
                                <v-textarea v-model="atendimento.ate_descricao" label="Descrição" :color="azulPadrao"></v-textarea>
                                <v-select v-model="atendimento.can_id" :items="canais" item-value="can_id" item-text="can_nome" label="Canal" :color="azulPadrao"></v-select>
                                <v-checkbox v-model="atendimento.ate_urgente" label="Urgente?" :color="azulPadrao" class="ml-2"></v-checkbox>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn class="ml-2 mb-2" @click="criarAtendimento" :color="azulPadrao" dark>Criar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="mr-2 mb-2" @click="voltarCodigo" :color="azulPadrao" dark outlined>Voltar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import PageTitle from '@/components/templates/PageTitle'
import { baseApiUrl, azulPadrao, showError, segredoAtendimento } from '@/config/global'
import jwt from 'jwt-simple'
import axios from 'axios'

export default {
    name: 'CriarAtendimentoCliente',
    components: {
        PageTitle
    },
    data() {
        return {
            azulPadrao,
            codigoUser: '',
            codigoValido: false,
            atendimento: {},
            empresas: [],
            setores: [],
            usuarios: [],
            canais: []
        }
    },
    methods: {
        validarCodigo() {
            axios.post(`${baseApiUrl}/validar_codigo_usuario`, { codigo: this.codigoUser })
                .then(r => {
                    this.atendimento = {}

                    this.empresas = [{
                        emp_id: r.data.emp_id,
                        emp_razao_social: r.data.emp_razao_social
                    }]
                    
                    this.setores = [{
                        set_id: r.data.set_id,
                        set_nome: r.data.set_nome
                    }]
                    
                    this.usuarios = [{
                        usr_id: r.data.usr_id,
                        usr_nome: r.data.usr_nome
                    }]

                    this.canais = r.data.canais

                    this.atendimento.emp_id = r.data.emp_id
                    this.atendimento.set_id = r.data.set_id
                    this.atendimento.usr_id = r.data.usr_id
                    this.codigoValido = true
                })
                .catch(e => {
                    this.codigoValido = false
                    showError(e)
                })
        },
        criarAtendimento() {
            if (!this.atendimento.ate_urgente) {
                this.atendimento.ate_urgente = false
            }

            //Cria um código criptografado com a data atual (objeto) pra proteger, ao menos um pouco, essa rota que está totalmente aberta
            const d = new Date()
            const objD = {
                dia: d.getUTCDate(),
                mes: d.getUTCMonth() +1,
                ano: d.getUTCFullYear()
            }

            const codigoValidacao = jwt.encode(objD, segredoAtendimento)
            this.atendimento.codigo_validacao = codigoValidacao

            axios.post(`${baseApiUrl}/atendimento_aberto`, this.atendimento)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.voltarCodigo()
                })
                .catch(showError)
        },
        voltarCodigo() {
            this.atendimento = {}
            this.canais = []
            this.codigoUser = ''
            this.codigoValido = false
        }
    }
}
</script>

<style>

</style>