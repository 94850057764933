<template>
    <v-app class="app">
        <Header />
        <Loading v-if="validatingToken" />
        <Monitorar v-else-if="user && user.usr_monitorar" />
		<Content v-else />
        <Footer />
    </v-app>
</template>

<script>
import Header from '@/components/templates/Header.vue'
import Loading from '@/components/templates/Loading.vue'
import Monitorar from '@/components/templates/Monitorar.vue'
import Content from '@/components/templates/Content.vue'
import Footer from '@/components/templates/Footer.vue'
import axios from 'axios'
import { mapState } from 'vuex'
import { userKey, baseApiUrl } from '@/config/global'

export default {
    name: "App",
    components: {
        Header,
        Loading,
        Monitorar,
        Content,
        Footer
    },
    computed: mapState(['user']),
    data() {
        return {
            validatingToken: true
        }
    },
    methods: {
        async validateToken() {
            this.validatingToken = true

            const json = localStorage.getItem(userKey)
            const userData = JSON.parse(json)
            this.$store.commit('setUser', null)

            if (!userData) {
                this.validatingToken = false
                if ((this.$route.name != 'novaEmpresa') && (this.$route.name != 'criarAtendimentoCliente')) {
                    this.$router.push({ name: 'loginPage' })
                }
                return
            }

            const res = await axios.post(`${baseApiUrl}/validarToken`, userData)

            if (res.data) {
                this.$store.commit('setUser', userData)
            } else {
                localStorage.removeItem(userKey)
                if ((this.$route.name != 'novaEmpresa') && (this.$route.name != 'criarAtendimentoCliente')) {
                    this.$router.push({ name: 'loginPage' })
                }
            }

            this.validatingToken = false
        }
    },
    created() {
        this.validateToken()
    }
}
</script>

<style>
    * {
        font-family: "Lato", sans-serif;
    }
</style>