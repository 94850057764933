<template>
    <v-container class="atendimento-page" fluid>
        <PageTitle title="Atendimento" subtitle="Código" :subtitleStrong="atendimentoId" />
        <v-layout align-center justify-center>
            <v-flex>
                <v-card>
                    <v-card-text>
                        <v-form>
                            <v-text-field v-model="atendimento.ate_titulo" label="Título" :color="azulPadrao" readonly></v-text-field>
                            <v-textarea v-model="atendimento.ate_descricao" label="Descrição" :color="azulPadrao" readonly></v-textarea>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="showInfs = !showInfs" class="mt-0 ml-2 mb-4" outlined tile :color="azulPadrao" small>
                            <v-icon>{{ showInfs ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                            {{ showInfs ? 'Menos' : 'Mais' }} informações
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="showInfs">
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-form>
                                    <v-text-field v-model="atendimento.set_nome" label="Setor" :color="azulPadrao" readonly></v-text-field>
                                    <v-text-field v-model="atendimento.usr_nome" label="Usuário" :color="azulPadrao" readonly></v-text-field>
                                    <v-text-field v-model="atendimento.ate_cliente" label="Cliente" :color="azulPadrao" readonly></v-text-field>
                                    <v-text-field v-model="atendimento.can_nome" label="Canal" :color="azulPadrao" readonly></v-text-field>
                                    <v-checkbox v-model="atendimento.ate_urgente" label="Urgente?" :color="azulPadrao" readonly></v-checkbox>
                                    <v-text-field v-model="atendimento.sta_descricao" label="Status" :color="azulPadrao" readonly></v-text-field>
                                    <v-text-field v-model="atendimento.conclusao_formatada" label="Conclusão" :color="azulPadrao" readonly></v-text-field>
                                    <v-text-field v-model="atendimento.usr_criacao" label="Usuário criação" :color="azulPadrao" readonly></v-text-field>
                                    <v-text-field v-model="atendimento.criacao_formatada" label="Criação" :color="azulPadrao" readonly></v-text-field>
                                    <v-text-field v-model="atendimento.alteracao_formatada" label="Última alteração" :color="azulPadrao" readonly></v-text-field>
                                </v-form>
                            </v-card-text>
                        </div>
                    </v-expand-transition>
                </v-card>
            </v-flex>
        </v-layout>
        <br>
        <v-layout align-center justify-center>
            <v-flex>
                <v-card>
                    <v-card-title>
                        <span class="text-h5"><strong>Históricos</strong></span>
                        <v-spacer></v-spacer>

                        <v-btn @click="dialog = true" class="ml-4" icon max-width="40">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-dialog v-model="dialog" max-width="600px">
                            <v-card v-if="atendimento.ate_conclusao">
                                <v-card-title>
                                    <span class="text-h5">Atendimento já foi concluído</span>
                                </v-card-title>
                                <v-card-text>
                                    <p>Não é permitido incluir mais históricos.</p>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn class="ml-2 mb-2" @click="dialog = false" :color="azulPadrao" dark>Ok</v-btn>
                                </v-card-actions>
                            </v-card>
                            <CriarStatusAtendimento v-else :fecharDialog="fecharDialog" :atendimentoId="atendimentoId" />
                        </v-dialog>

                    </v-card-title>
                    <v-card-text>
                        <v-data-table hide-default-footer :headers="colunas" :items="historicos" no-data-text="Nenhum histórico" :items-per-page="-1">
                            <template v-slot:[`item.btnActions`]="{ item }">
                                <v-btn class="mr-2" @click="atualizarObservacaoHistorico(item.std_observacao)" x-small :color="azulPadrao" dark>Observação</v-btn>
                                <v-dialog v-model="dialogObs" max-width="600px" :retain-focus="false">
                                    <v-card>
                                        <v-card-title>Observação</v-card-title>
                                        <v-card-text>
                                            <p>{{ observacaoHistorico ? observacaoHistorico : 'Nenhuma observação digitada pelo usuário no momento da inclusão do histórico.' }}</p>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn class="ml-2 mb-2" @click="fecharDialogObs" :color="azulPadrao" dark>Ok</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import PageTitle from '@/components/templates/PageTitle'
import CriarStatusAtendimento from '@/components/others/CriarStatusAtendimento'
import { azulPadrao, baseApiUrl, showError, formatarDataHora } from '@/config/global'
import axios from 'axios'

export default {
    name: 'AtendimentoPage',
    props: [
        'atendimentoId'
    ],
    components: {
        PageTitle,
        CriarStatusAtendimento
    },
    data() {
        return {
            azulPadrao,
            formatarDataHora,
            dialog: false,
            atendimento: {},
            historicos: [],
            showInfs: false,
            dialogObs: false,
            observacaoHistorico: '',
            colunas: [
                {
                    text: 'Código',
                    value: 'std_id'
                },
                {
                    text: 'Status',
                    value: 'sta_descricao'
                },
                {
                    text: 'Usuário',
                    value: 'usr_nome'
                },
                {
                    text: 'Data',
                    value: 'data_formatada'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        atualizarAtendimento() {
            //Atendimento
            axios.get(`${baseApiUrl}/atendimentos/${this.atendimentoId}`)
                .then(r => {
                    this.atendimento = r.data.data[0]
                    this.atendimento.criacao_formatada = this.formatarDataHora(this.atendimento.ate_criacao)
                    this.atendimento.alteracao_formatada = this.formatarDataHora(this.atendimento.ate_ultima_alteracao)
                    this.atendimento.conclusao_formatada = this.formatarDataHora(this.atendimento.ate_conclusao)
                })
                .catch(showError)

            //Históricos
            axios.get(`${baseApiUrl}/atendimento_status/${this.atendimentoId}`)
                .then(r => {
                    this.historicos = []
                    r.data.data.forEach(el => {
                        el.data_formatada = this.formatarDataHora(el.std_data)
                        this.historicos.push(el)
                    });
                })
                .catch(showError)
        },
        fecharDialog() {
            this.dialog = false
            this.atualizarAtendimento()
        },
        atualizarObservacaoHistorico(desc) {
            this.observacaoHistorico = desc
            this.dialogObs = true
        },
        fecharDialogObs() {
            this.dialogObs = false
        }
    },
    mounted() {
        if (this.atendimentoId) {
            this.atualizarAtendimento()
        } else {
            this.$router.push({
                name: 'homePage'
            })
        }
    }
}
</script>

<style>

</style>