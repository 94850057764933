<template>
    <v-container class="atendimentos-admin-page" fluid>
        <v-layout align-center justify-center>
            <v-flex>
                <v-card>
                    <v-card-title>
                        <span class="text-h5"><strong>Atendimentos em aberto</strong></span>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table hide-default-footer :item-class="corFonteUrgente" :headers="colunasAbertos" :items="atendimentosAbertos" no-data-text="Nenhum atendimento em aberto" :items-per-page="-1">
                            <template v-slot:[`item.ate_criacao`]="{ item }">
                                {{ formatarDataHora(item.ate_criacao) }}
                            </template>
                            <template v-slot:[`item.ate_ultima_alteracao`]="{ item }">
                                {{ formatarDataHora(item.ate_ultima_alteracao) }}
                            </template>
                            <template v-slot:[`item.ate_urgente`]="{ item }">
                                {{ formatarBoolTexto(item.ate_urgente) }}
                            </template>
                            <template v-slot:[`item.btnActions`]="{ item }">
                                <v-btn class="mr-2" @click="openAtendimento(item.ate_id)" x-small :color="azulPadrao" dark>Abrir</v-btn>
                            </template>
                        </v-data-table>
                        <div class="text-center">
                            <v-pagination v-model="pageAbertos" :length="maxPageAbertos" v-if="maxPageAbertos > 1" :color="azulPadrao"></v-pagination>
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <br>
        <v-layout align-center justify-center>
            <v-flex>
                <v-card>
                    <v-card-title>
                        <span class="text-h5"><strong>Todos os atendimentos</strong></span>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table hide-default-footer :headers="colunasTodos" :items="atendimentos" no-data-text="Nenhum atendimento cadastrado" :items-per-page="-1">
                            <template v-slot:[`item.ate_criacao`]="{ item }">
                                {{ formatarDataHora(item.ate_criacao) }}
                            </template>
                            <template v-slot:[`item.ate_ultima_alteracao`]="{ item }">
                                {{ formatarDataHora(item.ate_ultima_alteracao) }}
                            </template>
                            <template v-slot:[`item.ate_conclusao`]="{ item }">
                                {{ formatarDataHora(item.ate_conclusao) }}
                            </template>
                            <template v-slot:[`item.btnActions`]="{ item }">
                                <v-btn class="mr-2" @click="openAtendimento(item.ate_id)" x-small :color="azulPadrao" dark>Abrir</v-btn>
                            </template>
                        </v-data-table>
                        <div class="text-center">
                            <v-pagination v-model="page" :length="maxPage" v-if="maxPage > 1" :color="azulPadrao"></v-pagination>
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>

        <v-btn class="mt-4" @click="loadAllAtendimentos" :color="azulPadrao" dark>Atualizar</v-btn>
    </v-container>
</template>

<script>
import { azulPadrao, baseApiUrl, showError, formatarDataHora, formatarBoolTexto } from '@/config/global'
import axios from 'axios'

export default {
    name: 'AtendimentosAdminPage',
    data() {
        return {
            azulPadrao,
            formatarDataHora,
            formatarBoolTexto,
            page: 1,
            maxPage: 1,
            pageAbertos: 1,
            maxPageAbertos: 1,
            atendimentos: [],
            atendimentosAbertos: [],
            colunasTodos: [
                {
                    text: 'Código',
                    value: 'ate_id'
                },
                {
                    text: 'Título',
                    value: 'ate_titulo'
                },
                {
                    text: 'Cliente',
                    value: 'ate_cliente'
                },
                {
                    text: 'Usuário',
                    value: 'usr_nome'
                },
                {
                    text: 'Status',
                    value: 'sta_descricao'
                },
                {
                    text: 'Criação',
                    value: 'ate_criacao'
                },
                {
                    text: 'Última alteração',
                    value: 'ate_ultima_alteracao'
                },
                {
                    text: 'Conclusão',
                    value: 'ate_conclusao'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ],
            colunasAbertos: [
                {
                    text: 'Código',
                    value: 'ate_id'
                },
                {
                    text: 'Título',
                    value: 'ate_titulo'
                },
                {
                    text: 'Cliente',
                    value: 'ate_cliente'
                },
                {
                    text: 'Usuário',
                    value: 'usr_nome'
                },
                {
                    text: 'Urgente',
                    value: 'ate_urgente'
                },
                {
                    text: 'Status',
                    value: 'sta_descricao'
                },
                {
                    text: 'Criação',
                    value: 'ate_criacao'
                },
                {
                    text: 'Última alteração',
                    value: 'ate_ultima_alteracao'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        loadAtendimentos() {
            //Todos os atendimentos
            this.atendimentos = []
            axios.get(`${baseApiUrl}/atendimentos_todos?page=${this.page}`)
                .then(r => {
                    this.atendimentos = r.data.data
                    if (r.data.pagination > r.data.count) {
                        this.maxPage = 1
                    } else {
                        const i = r.data.count / r.data.pagination
                        if (Number.isInteger(i)) {
                            this.maxPage = i
                        } else {
                            this.maxPage = Math.trunc(i) +1
                        }
                    }
                })
                .catch(showError)
        },
        loadAtendimentosAbertos() {
            //Atendimentos em aberto
            this.atendimentosAbertos = []
            axios.get(`${baseApiUrl}/atendimentos_abertos?page=${this.pageAbertos}`)
                .then(r => {
                    this.atendimentosAbertos = r.data.data
                    if (r.data.pagination > r.data.count) {
                        this.maxPageAbertos = 1
                    } else {
                        const i = r.data.count / r.data.pagination
                        if (Number.isInteger(i)) {
                            this.maxPageAbertos = i
                        } else {
                            this.maxPageAbertos = Math.trunc(i) +1
                        }
                    }
                })
                .catch(showError)
        },
        loadAllAtendimentos() {
            this.loadAtendimentos()
            this.loadAtendimentosAbertos()
        },
        openAtendimento(atendimentoId) {
            this.$router.push({
                name: 'atendimentoPage',
                params: {
                    atendimentoId
                }
            })
        },
        corFonteUrgente(item) {
            return item.ate_urgente ? 'cor-destacada' : 'cor-normal'
        }
    },
    watch: {
        page() {
            this.loadAtendimentos()
        },
        pageAbertos() {
            this.loadAtendimentosAbertos()
        }
    },
    mounted() {
        this.loadAllAtendimentos()
    }
}
</script>

<style>
    .cor-normal {
        color: black;
    }

    .cor-destacada {
        color: rgb(232, 74, 82);
        font-weight: bold;
    }
</style>