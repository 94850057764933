import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginPage from '@/components/pages/LoginPage'
import HomePage from '@/components/pages/HomePage'
import AdminPage from '@/components/pages/AdminPage'
import NovaEmpresaPage from '@/components/pages/NovaEmpresaPage'
import AtendimentoPage from '@/components/pages/AtendimentoPage'
import AtendimentosUsuario from '@/components/admin/AtendimentosUsuario'
import CriarAtendimentoCliente from '@/components/pages/CriarAtendimentoCliente'
import { userKey } from '@/config/global'

Vue.use(VueRouter)

const routes = [
    {
        name: 'homePage',
        path: '/',
        component: HomePage
    },
    {
        name: 'adminPage',
        path: '/admin',
        component: AdminPage,
        meta: {
            requiresAmdin: true
        }
    },
    {
        name: 'loginPage',
        path: '/login',
        component: LoginPage
    },
    {
        name: 'novaEmpresa',
        path: '/nova_empresa',
        component: NovaEmpresaPage
    },
    {
        name: 'atendimentoPage',
        path: '/atendimento',
        component: AtendimentoPage,
        props: true //Dessa forma aceita passar parâmetros na chamada diretamente pelo router
    },
    {
        name: 'atendimentosUsuario',
        path: '/atendimentos_user',
        component: AtendimentosUsuario,
        props: true
    },
    {
        name: 'criarAtendimentoCliente',
        path: '/criar_atendimento',
        component: CriarAtendimentoCliente
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAmdin)) {
        const j = localStorage.getItem(userKey)
        const u = JSON.parse(j)
        if (u && u.usr_admin) {
            next()
        } else {
            next({
                name: 'homePage'
            })
        }
    } else {
        next()
    }
})

export default router